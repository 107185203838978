import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const SessionGroupList = lazy(() => import("./sessionGroup-list/SessionGroupList"));
const SessionGroupCreate = lazy(() => import("./sessionGroup-create/SessionGroupCreate"));
const SessionGroupEdit = lazy(() => import("./sessionGroup-edit/SessionGroupEdit"));
const SessionGroupView = lazy(() => import("./sessionGroup-view/SessionGroupView"));


const SessionGroupRoutes = () => {
  return (
    <Routes>
    <Route
      path="/"
      element={
          <EventDetails Component={SessionGroupList} />
      }
    />
    <Route
      path="/create"
      element={
          <EventDetails Component={SessionGroupCreate} />
      }
    />
    <Route
      path="/:groupSlug/edit"
      element={
          <EventDetails Component={SessionGroupEdit} />
      }
    />
    <Route
      path="/:groupSlug/view"
      element={
          <EventDetails Component={SessionGroupView} />
      }
    />
  </Routes>
  )
}

export default SessionGroupRoutes
