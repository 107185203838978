import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const SessionLocationList = lazy(() =>
  import("./sessionLocation-list/SessionLocationList")
);
const SessionLocationCreate = lazy(() =>
  import("./sessionLocation-create/SessionLocationCreate")
);
const SessionLocationEdit = lazy(() =>
  import("./sessionLocation-edit/SessionLocationEdit")
);
const SessionLocationView = lazy(() =>
  import("./sessionLocation-view/SessionLocationView")
);

const SessionRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={SessionLocationList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={SessionLocationCreate} />}
      />
      <Route
        path="/:locationSlug/edit"
        element={<EventDetails Component={SessionLocationEdit} />}
      />
      <Route
        path="/:locationSlug/view"
        element={<EventDetails Component={SessionLocationView} />}
      />
    </Routes>
  );
};

export default SessionRoutes;
