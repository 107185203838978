import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSidebar from "../../ProfileSidebar";


const CustomFieldsList = lazy(() =>
  import("./custom-fields-list/CustomFieldsList")
);
const CreateCustomFields = lazy(() =>
  import("./create-custom-fields/CreateCustomFields")
);
const EditCustomFields = lazy(() =>
  import("./edit-custom-fields/EditCustomFields")
);
const CustomFieldsView = lazy(() =>
  import("./custom-fields-view/CustomFieldsView")
);


const CustomFieldsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProfileSidebar Component={CustomFieldsList} />}
      />
       <Route
        path="/create"
        element={<ProfileSidebar Component={CreateCustomFields} />}
      />
       <Route
        path="/edit/:slug"
        element={<ProfileSidebar Component={EditCustomFields} />}
      />
       <Route
        path="/view/:slug"
        element={<ProfileSidebar Component={CustomFieldsView} />}
      />
    
    </Routes>
  );
};

export default CustomFieldsRoutes
