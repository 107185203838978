import React from 'react'
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

//Invitation routes
const SessionEmailList = lazy(() => import("./session-email-list/sessionEmailList"));
const SessionEmailCreate = lazy(() =>  import("./session-email-create/sessionEmailCreate"));
const SessionEmailEdit = lazy(() => import("./session-email-edit/sessionEmailEdit"));
const SessionEmailView = lazy(() => import("./session-email-view/sessionEmailView"));

const SessionEmailRoutes = () => {
  return (
    <>
      <Routes>
          <Route path="/" element={<EventDetails Component={SessionEmailList} />} />
          <Route
              path="/create"
              element={<EventDetails Component={SessionEmailCreate} />}
          />
          <Route
              path="/:emailSlug/edit"
              element={<EventDetails Component={SessionEmailEdit} />}
          />
          <Route
              path="/:emailSlug/view"
              element={<EventDetails Component={SessionEmailView} />}
          />
      </Routes>
    </>
  )
}

export default SessionEmailRoutes


