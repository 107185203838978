import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const AdmissionItemList = lazy(() =>
  import("./admission-Item-list/AdmissionItemList")
);
const AdmissionItemCreate = lazy(() =>
  import("./admission-Item-create/AdmissionItemCreate")
);
const AdmissionItemEdit = lazy(() =>
  import("./admission-Item-edit/AdmissionItemEdit")
);
const AdmissionItemView = lazy(() =>
  import("./admission-Item-view/AdmissionItemView")
);

const AdmissionItemRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={AdmissionItemList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={AdmissionItemCreate} />}
      />
      <Route
        path="/:admissionSlug/edit"
        element={<EventDetails Component={AdmissionItemEdit} />}
      />
      <Route
        path="/:admissionSlug/view"
        element={<EventDetails Component={AdmissionItemView} />}
      />
    </Routes>
  );
};


export default AdmissionItemRoutes
