export const DARKTHEME = {
  THEME: "dark",
  COLOR: "white",
  BACKGROUD: "dark",
  BACKGROUNDCOLOR: "#333",
  GREY: "grey",
  THEMETYPE: "light",
};
export const LIGHTTHEME = {
  THEME: "light",
  COLOR: "dark",
  BACKGROUD: "white",
  BACKGROUNDCOLOR: "rgb(230 230 230)",
  GREY: "grey",
  THEMETYPE: "dark",
};
