import { STATUS, DISPLAY_STATUS, SESSIONTYPE } from "../constants/Constants";
import {  useRef } from "react";

/**
 * Function to convert a status value to a corresponding HTML string with color-coded text.
 *
 * @param {number | string} status - The status value to be converted.
 * @returns {string} - The HTML string with color-coded text based on the status value.
 */
export const status = (status) => {
  if (status) {
    // Assuming STATUS is an object containing constants for status values
    switch (status.toString()) {
      case STATUS.ACTIVE:
        return `<span class="text-success">Active</span>`;
      case STATUS.INACTIVE:
        return `<span class="text-danger">Inactive</span>`;
      default:
        return "-";
    }
  }
};
// End

/**
 * Function to format a date to a specific format (day, month, and year).
 *
 * @param {Date | string} date - The date to be formatted.
 * @returns {string} - The formatted date string in the format "day month year".
 */
export const dateFormate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};
// End

/**
 * Function to format a time to a specific format (hour and minute).
 *
 * @param {Date | string} date - The time to be formatted.
 * @returns {string} - The formatted time string in the format "hour:minute AM/PM".
 */
export const timeFormate = (date) => {
  const formattedTime = new Date(date).toLocaleTimeString("en-GB", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedTime.toUpperCase(); // Convert the formattedTime to uppercase
};
// End

/**
 * Function to convert a date to UTC format by adjusting for the local timezone offset.
 *
 * @param {Date | string} date - The date to be converted to UTC.
 * @returns {string | null} - The date in ISO format adjusted for the local timezone offset, or null if the input date is falsy.
 */
export const timeZone = (date) => {
  if (date) {
    const localDate = new Date(date);

    // Get the timezone offset and convert the date to UTC
    const timezoneOffset = localDate.getTimezoneOffset();
    localDate.setMinutes(localDate.getMinutes() - timezoneOffset);

    return localDate.toISOString();
  } else {
    return null;
  }
};
// End

/**
 * Function to convert the session type value to a formatted string.
 *
 * @param {string} value - The session type value to be converted.
 * @returns {string} - A formatted string representing the session type, or "-" if the value is not recognized.
 */
export const SessionType = (value) => {
  let result;
  switch (value) {
    case SESSIONTYPE.INCLUDED:
      result = `<span class="text-success">Included</span>`;
      break;
    case SESSIONTYPE.OPTIONAL:
      result = `<span class="text-primary">Optional</span>`;
      break;
    default:
      result = "-";
      break;
  }
  return result;
};
//END

/**
 * Function to capitalize the first letter of a string.
 *
 * @param {string} value - The input string to be capitalized.
 * @returns {string} - The input string with the first letter capitalized.
 */
export const CapitalizeFirst = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
//END

// change api response data for select
/**
 * Transform API response data to options format for select dropdown.
 * @param {array} res - The API response data.
 * @param {string} label - The key in the response data representing the label for the options.
 * @param {string} value - The key in the response data representing the value for the options.
 * @returns {array} - An array of objects with label and value properties, suitable for select dropdown options.
 */
export const apiResponseChangeForSelect = (res, label, value) => {
  var transformedOptions = [];
  if (res && res.length > 0) {
    transformedOptions = res.map((option) => ({
      value: option[value],
      label: option[label],
    }));
  } else {
    transformedOptions = [];
  }
  return transformedOptions;
};
// End
//change api responce for select value
/**
 * Create a response object for select values.
 * @param {string} label - The label for the select option.
 * @param {any} value - The value associated with the select option.
 * @returns {object} - An object with label and value properties.
 */
export const responseChangeForSelectValue = (label, value) => {
  var responseChange = {
    label: label,
    value: value,
  };
  return responseChange;
};
// End

/**
 * Function to capitalize the first letter of each word in a string.
 *
 * @param {string} str - The input string in which each word needs to be capitalized.
 * @returns {string} - The input string with the first letter of each word capitalized.
 */
export const capitalizeWords = (str) => {
  return str
    .split(" ") // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a string
};
// End

/**
 * Function to format and display start and end dates.
 *
 * @param {string} start - The start date to be formatted (should be a valid date string).
 * @param {string} end - The end date to be formatted (should be a valid date string).
 * @returns {string} - The formatted date string. If both start and end dates are provided,
 * it returns the formatted start date. If dates are different, it returns a string with a
 * hyphen between the formatted start and end dates.
 */
export const showStartAndEndDate = (start, end) => {
  var FinalDate = "";

  // Check if both start and end dates are provided
  if (start && end) {
    const startDate = dateFormate(start); // Format the start date using the dateFormate function
    const endDate = dateFormate(end); // Format the end date using the dateFormate function

    // Check if the formatted start date is the same as the formatted end date
    switch (startDate === endDate) {
      case true:
        FinalDate = startDate; // If both dates are the same, set FinalDate to the formatted start date
        break;
      case false:
        FinalDate = startDate + " - " + endDate; // If dates are different, set FinalDate with a hyphen between formatted start and end dates
        break;
      default:
        break;
    }
  }

  return FinalDate; // Return the final formatted date string
};
// End

/**
 * Function to format and display start and end times.
 *
 * @param {string} start - The start time to be formatted (should be a valid time string).
 * @param {string} end - The end time to be formatted (should be a valid time string).
 * @returns {string} - The formatted time string. If both start and end times are provided,
 * it returns the formatted start time. If times are different, it returns a string with a
 * hyphen between the formatted start and end times.
 */
export const showStartAndEndTime = (start, end) => {
  var FinalTime = "";

  // Check if both start and end times are provided
  if (start && end) {
    const startTime = timeFormate(start); // Format the start time using the timeFormate function
    const endTime = timeFormate(end); // Format the end time using the timeFormate function

    // Check if the formatted start time is the same as the formatted end time
    switch (startTime === endTime) {
      case true:
        FinalTime = startTime; // If both times are the same, set FinalTime to the formatted start time
        break;
      case false:
        FinalTime = startTime + " - " + endTime; // If times are different, set FinalTime with a hyphen between formatted start and end times
        break;
      default:
        break;
    }
  }

  return FinalTime; // Return the final formatted time string
};
// End

/**
 * Function to convert a status value to a corresponding HTML string with color-coded text.
 *
 * @param {number | string} status - The status value to be converted.
 * @returns {string} - The HTML string with color-coded text based on the status value.
 */
export const displayStatus = (status) => {
  if (status) {
    // Assuming STATUS is an object containing constants for status values
    switch (status.toString()) {
      case DISPLAY_STATUS.YES:
        return `<span class="text-success">Yes</span>`;
      case DISPLAY_STATUS.NO:
        return `<span class="text-danger">No</span>`;
      default:
        return "-";
    }
  }
};
// End

/**
 * Scrolls to and focuses on the field with a validation error.
 *
 * @param {string} fieldName - The name of the field with a validation error.
 * @param {Object} inputRefs - An object containing refs to input fields.
 */
export const scrollToAndFocusOnError = (fieldName, inputRefs) => {
  const fieldRef = inputRefs[fieldName];

  if (fieldRef && fieldRef.current) {
    // Focus on the field
    fieldRef.current.focus();

    // Scroll to the parent container
    const container = fieldRef.current.parentElement;
    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  }
};

/**
 * Generates an object of input refs for the specified field names.
 *
 * @param {Array} fieldNames - An array of field names.
 * @returns {Object} - An object containing refs to input fields.
 */
export const generateInputRefs = (fieldNames) => {
  const inputRefs = {};
  fieldNames.forEach((fieldName) => {
    inputRefs[fieldName] = useRef(null);
  });
  return inputRefs;
};

/**
 * Quill editor modules configuration.
 * @typedef {Object} QuillModules
 * @property {Array} toolbar - An array of toolbar options.
 * @property {string} comment - A comment describing the purpose of the modules.
 * @property {string} dataType - A data type description.
 */

/**
 * Quill editor modules.
 * @type {QuillModules}
 */
export const modules = {
  /**
   * Toolbar configuration.
   */
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'code-block'],
    ['clean'],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
  ],
};



/**
 * Converts a snake_case key to a display name format (capitalize first letter of each word).
 * @param {string} key - The snake_case key to be converted.
 * @returns {string} The converted display name.
 */
export const convertToDisplayName = (key) => {
  // Split the input key by underscore to separate words
  const words = key.split('_');
  // Capitalize the first letter of each word and join them back together
  return  words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};
