import { Suspense, lazy, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

import EventRoutes from "../pages/event/EventRoutes";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";

import PageNotFound from "../pages/page-not-found/PageNotFound";

// Admin routes
import CategoriesRoutes from "../pages/admin/event-categories/CategoriesRoutes";
import { Spinner } from "react-bootstrap";
import ThemeSettingContext from "../helper/context/theme-settings/ThemeSettingContext";
import ProfileRouters from "../pages/auth/update-details/ProfileRouters";
import RoleRoutes from "../pages/admin/role/RoleRoutes";
import PermissionRoutes from "../pages/admin/permission/PermissionRoutes";
import Verification from "../pages/auth/verification/Verification";
import SignUpVerification from "../pages/auth/signup-verification/SignUpVerification";
import PrivateRoute from "./PrivateRoute";
import RegistrationFormRoutes from "../pages/registration-form/routes/RegistrationFormRoutes";
import UserssRoutes from "../pages/admin/users/UsersRoutes";
// End

const Login = lazy(() => import("../pages/auth/login/Login"));
const Register = lazy(() => import("../pages/auth/register/Register"));
const ForgotPassword = lazy(() =>
  import("../pages/auth/forgot-password/Forgotpassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../pages/auth/forgot-password/reset-password/ResetPassword")
);

const RouterComponent = () => {
const isAuthenticated = useIsAuthenticated();
const { theme } = useContext(ThemeSettingContext); //set theme
// Get the current path
const currentPath = window.location.pathname;
// Check if the current path contains any of the specified routes
// Define an array of special routes
const specialRoutes = [
  "/personal-information",
  "/summary",
  "/confirmation",
  "/cancellation",
  "/decline",
  "/guest-information",
  "/login"
];
// Check if the current path matches any of the specified routes
const isSpecialRoute = specialRoutes.some((route) => currentPath.endsWith(route));
  return (
    <>
      <Router>
        {!isSpecialRoute ?  isAuthenticated() && <Header />:''}
        <Suspense
          fallback={
            <div className="text-center d-flex align-items-center justify-content-center h-100vh">
              <Spinner animation="border" variant={`${theme.THEMETYPE}`} />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={isAuthenticated() ? <Navigate to="/events" /> : <Login />}/>
            <Route path="/register" element={isAuthenticated() ? <Navigate to="/events" /> : <Register />}/>
            <Route path="/forgot-password" element={isAuthenticated() ? (<Navigate to="/events" />) : (<ForgotPassword />)}/>
            <Route path="/reset-password" element={isAuthenticated() ? (<Navigate to="/events" />) : (<ResetPassword />)}/>
            <Route path="/verification" element={isAuthenticated() ? <Navigate to="/events" /> : <Verification />}/>
            <Route path="/events/*" element={<PrivateRoute Component={EventRoutes} />}/>
            {/* Amin routes */}
            {/* Category */}
            <Route path="/categories/*" element={<PrivateRoute Component={CategoriesRoutes} />}/>
            {/* End */}
            <Route path="/roles/*" element={<PrivateRoute Component={RoleRoutes} />}/>
            <Route path="/permissions/*" element={<PrivateRoute Component={PermissionRoutes} />}/>
            {/* End */}
             {/* User */}
             <Route path="/users/*" element={<PrivateRoute Component={UserssRoutes} />}/>
            {/* End */}
            <Route path="/register-success" element={isAuthenticated() ? (<Navigate to="/events" />) : (<SignUpVerification />)}/>
            {/* End */}
            {/* Profile route */}
            <Route path="/admin/*" element={<PrivateRoute Component={ProfileRouters} />}/>
            {/* End */}
            {/* Registration form route */}
            <Route path="/event/*" element={<RegistrationFormRoutes/>} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
        {!isSpecialRoute ?  isAuthenticated() && <Footer />:''}
      </Router>
    </>
  );
};
export default RouterComponent;
