import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const FeedbackSurveyList = lazy(() =>
  import("./feedback-survey-list/FeedbackSurveyList")
);
const TestSurvey = lazy(() =>
  import("./test-survey/TestSurvey")
);
// const SessionBundlesEdit = lazy(() =>
//   import("./session-bundles-edit/SessionBundlesEdit")
// );
// const SessionBundlesView = lazy(() =>
//   import("./session-bundles-view/SessionBundlesView")
// );

const FeedbackSurveyRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={FeedbackSurveyList} />}
      />
       <Route
        path="/testsurvey"
        element={<EventDetails Component={TestSurvey} />}
      />
    </Routes>
  );
};

export default FeedbackSurveyRoutes;
