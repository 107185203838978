import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const BadgesList = lazy(() => import("./badges-list/BadgesList"));

const BadgesCreate = lazy(() => import("./badges-create/BadgesCreate"));

const BadgesEdit = lazy(() => import("./badges-edit/BadgesEdit"));

const BadgesView = lazy(() => import("./badges-view/BadgesView"));

const BadgesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={BadgesList} />} />
      <Route
        path="/create"
        element={<EventDetails Component={BadgesCreate} />}
      />
      <Route
        path="/:badgeSlug/edit"
        element={<EventDetails Component={BadgesEdit} />}
      />
      <Route
        path="/:badgeSlug/view"
        element={<EventDetails Component={BadgesView} />}
      />
    </Routes>
  );
};

export default BadgesRoutes;
