import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AdminSideBar from "../AdminSideBar";
import isAdminAuth from "../../../helper/middleware/isAdminAuth";
//Event Routes

const PermissionList = lazy(() =>
  import("./permission-list/Permission").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const PermissionCreate = lazy(() =>
  import("./permission-create/PermissionCreate").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const PermissionEdit = lazy(() =>
  import("./permission-edit/PermissionEdit").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);

const PermissionRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AdminSideBar Component={PermissionList}></AdminSideBar>}
      />
      <Route
        path="/create"
        element={<AdminSideBar Component={PermissionCreate}></AdminSideBar>}
      />
      <Route
        path="/edit/:id"
        element={<AdminSideBar Component={PermissionEdit}></AdminSideBar>}
      />
    </Routes>
  );
};
export default PermissionRoutes;
