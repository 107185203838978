import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import Select from "react-select";
import QuestionSummaryController from './QuestionSummaryController';
import { Line } from 'react-chartjs-2';

const QuestionSummary = () => {
  const {theme} = QuestionSummaryController()
  return (
    <Card className={`bg-${theme.THEME}`}>
      <Card.Header>
        <div className={`d-flex justify-content-between text-${theme.COLOR}`}>
          <div>Question Summary</div>
          
        </div>
      </Card.Header>
      <Card.Body>
        <div className="white-box">
          <Card.Title className="mb-3"> Filters</Card.Title>
          <Row>
            <Col xs={12} md={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label className={`text-${theme.COLOR}`}>View</Form.Label>
                <Select
                    options={[]}
                    isSearchable
                    // value={formField.category}
                    // onChange={(e) => handleChange(e, 'category')}
                    className={`bg-${theme.BACKGROUD} text-${theme.COLOR} w-100`}
                    placeholder="Select View"
                    isClearable
                />
              </Form.Group>
            </Col>
           
          </Row>
   
          <Card.Title className="mb-3">Table</Card.Title>
         
          <Row>
            <Col xs={12} md={12} xl={12}>
              <Form.Label>General</Form.Label>
              <Table  bordered >
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Format</th>
                    <th>Response</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3} className='text-center'>No Data Found</td>
                  </tr>
                </tbody>
              </Table>
              <p><b>Note:</b> These summaries are not available for File Upload, Matrix, and Open-Ended Text question types.</p>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  )
}

export default QuestionSummary
