import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import SignUpVerificationController from "./SignUpVerificationController";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SignUpVerification = () => {
  const { theme } = SignUpVerificationController();
  return (
    <>
      <Container>
        <div className="outer-card-box justify-content-center align-items-center d-flex h-100vh">
          <Row className="w-100 justify-content-center">
            <Col xs={11} md={5} xl={5}>
              <Card className={`bg-${theme.THEME}`}>
                <Card.Body className="text-center">
                  <div className={"success_icon icon_design mb-2"}>
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    <div>
                      <span>
                       <h5 className="text-theme-color my-1">Congratulations!</h5>
                         Your registration is completed.<br/> A
                        verification email has been sent to your email address.<br/>
                        Please check your inbox to complete the verification
                        process.
                      </span>
                    </div>
                  </div>
                  <div>
                    <Link to={`/`} className="btn btn-primary btn-theme-background mt-2 " >
                      Login
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default SignUpVerification;
