import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import SideBarController from "./SideBarController";

const Sidebar = ({ slug }) => {
  const { toggleSubMenu, isSubMenuOpen, theme } = SideBarController();
  return (
    <ListGroup className="event_menu">
      <NavLink
        to={`/events/${slug}/dashboard`}
        className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
      >
        Dashboard
      </NavLink>

      <div
        className={`text-decoration-none  px-3 py-2 f cursor-pointer ${
          isSubMenuOpen("general") ? "actived" : ""
        }`}
        onClick={() => toggleSubMenu("general")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>General</span>
          <span>
            {isSubMenuOpen("general") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("general") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/event-information`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Event Information
            </NavLink>
            <NavLink
              to={`/events/${slug}/general/features`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Event Features
            </NavLink>
            <NavLink
              to={`/events/${slug}/registration-types`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Registration Types
            </NavLink>
            <NavLink
              to={`/events/${slug}/event-settings`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Event Settings
            </NavLink>
          </ListGroup>
        )}
      </div>
      <div
        className={`text-decoration-none  px-3 py-2 f cursor-pointer ${
          isSubMenuOpen("planning") ? "actived" : ""
        }`}
        onClick={() => toggleSubMenu("planning")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Planning</span>
          <span>
            {isSubMenuOpen("planning") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("planning") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/documents`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Documents
            </NavLink>
            <NavLink
              to={`/events/${slug}/staffs`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Staff
            </NavLink>
            <NavLink
              to={`/events/${slug}/seatings`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Seating
            </NavLink>
          </ListGroup>
        )}
      </div>
      <NavLink
        to={`/events/${slug}/speakers`}
        className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
      >
        Speakers
      </NavLink>
      <div
        className={`text-decoration-none px-3 py-2 cursor-pointer`}
        onClick={() => toggleSubMenu("agenda")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Agenda</span>
          <span>
            {isSubMenuOpen("agenda") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("agenda") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/sessionlist`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Session List
            </NavLink>
            <NavLink
              to={`/events/${slug}/session-groups`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Session Groups
            </NavLink>
            <NavLink
              to={`/events/${slug}/session-bundles`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Session Bundles
            </NavLink>
            <NavLink
              to={`/events/${slug}/session-location`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Session Location
            </NavLink>
          </ListGroup>
        )}
      </div>

      <div
        className={`text-decoration-none px-3 py-2 cursor-pointer`}
        onClick={() => toggleSubMenu("marketing")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Marketing</span>
          <span>
            {isSubMenuOpen("marketing") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("marketing") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/web-links`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Weblinks
            </NavLink>
            <NavLink
              to={`/events/${slug}/embedded-widgets`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Embedded Widgets
            </NavLink>
            <NavLink
              to={`/events/${slug}/custom-data`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Custom Data Tags
            </NavLink>
            <NavLink
              to={`/events/${slug}/lang-management`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Language Management
            </NavLink>
          </ListGroup>
        )}
      </div>

      <div
        className={`text-decoration-none px-3 py-2 cursor-pointer`}
        onClick={() => toggleSubMenu("email")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Email</span>
          <span>
            {isSubMenuOpen("email") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("email") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/invitation`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Invitation Lists
            </NavLink>
            <NavLink
              to={`/events/${slug}/email-invitation`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Event Emails
            </NavLink>
            <NavLink
              to={`/events/${slug}/sessionemail`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Session Email
            </NavLink>

            <NavLink
              to={`/events/${slug}/planner-email`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Planner Alerts
            </NavLink>
            <NavLink
              to={`/events/${slug}/checklist-alert`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Registrant Checklist Alerts
            </NavLink>
            <NavLink
              to={`/events/${slug}/emails/recipient-settings`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Recipient Settings
            </NavLink>
          </ListGroup>
        )}
      </div>

      <div
        className={`text-decoration-none px-3 py-2 cursor-pointer`}
        onClick={() => toggleSubMenu("attendees")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Attendees</span>
          <span>
            {isSubMenuOpen("attendees") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("attendees") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/attendees`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Attendee List
            </NavLink>

            <NavLink
              to={`/events/${slug}/badges`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Badges and Certificates
            </NavLink>

            <NavLink
              to={`/events/${slug}/abandoned`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Abandoned Registrations
            </NavLink>

            {/* <NavLink
              to={`/events/${slug}/seating`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Internal Information
            </NavLink> */}
          </ListGroup>
        )}
      </div>
      <div
        className={`text-decoration-none  px-3 py-2 f cursor-pointer ${
          isSubMenuOpen("registration") ? "actived" : ""
        }`}
        onClick={() => toggleSubMenu("registration")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Registration</span>
          <span>
            {isSubMenuOpen("registration") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("registration") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/registration-settings`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Registration Settings
            </NavLink>
            <NavLink
              to={`/events/${slug}/admission-items`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Admission Items
            </NavLink>
            <NavLink
              to={`/events/${slug}/registration-process`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Registration Process
            </NavLink>
            <NavLink
              to={`/events/${slug}/advanced-rules`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Advanced Rules
            </NavLink>
            <NavLink
              to={`/events/${slug}/optional-items`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Optional Items
            </NavLink>
          </ListGroup>
        )}
      </div>
      <div
        className={`text-decoration-none  px-3 py-2 f cursor-pointer ${
          isSubMenuOpen("onArrival") ? "actived" : ""
        }`}
        onClick={() => toggleSubMenu("onArrival")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>OnArrival</span>
          <span>
            {isSubMenuOpen("onArrival") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("onArrival") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/on-arrival-settings`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              OnArrival Settings
            </NavLink>
          </ListGroup>
        )}
      </div>
      {/* Survey */}
      <div
        className={`text-decoration-none  px-3 py-2 f cursor-pointer ${
          isSubMenuOpen("surveys") ? "actived" : ""
        }`}
        onClick={() => toggleSubMenu("surveys")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>Surveys</span>
          <span>
            {isSubMenuOpen("surveys") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("surveys") && (
          <ListGroup>
            <NavLink
              to={`/events/${slug}/survey`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Feedback Surveys
            </NavLink>
            <NavLink
              response
              to={`/events/${slug}/response`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Responses
            </NavLink>
          </ListGroup>
        )}
      </div>
      {/* End */}
    </ListGroup>
  );
};

export default Sidebar;
