import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const EventInformation = lazy(() =>
  import("./view-event-information/EventInformation")
);
const EditEventInformation = lazy(() =>
  import("./edit-info/edit-event-information/EditEventInformation")
);

const EventInfoRoutes = () => {
  return (
    <Routes>
      <Route
        path="/event-information"
        element={<EventDetails Component={EventInformation} />}
      />
      <Route
        path="/edit-event-information"
        element={<EventDetails Component={EditEventInformation} />}
      />
    </Routes>
  );
};

export default EventInfoRoutes;
