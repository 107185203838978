import { ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ThemeSettingContext from "../../helper/context/theme-settings/ThemeSettingContext";
import { useContext } from "react";

const Sidebar = () => {
  const { theme } = useContext(ThemeSettingContext);
  return (
    <ListGroup className="admin_menu">
      <NavLink
        to="/events"
        className={`text-decoration-none text-${theme.COLOR} px-3 py-2 `}
      >
        Event
      </NavLink>

      <NavLink
        to="/categories"
        className={`text-decoration-none text-${theme.COLOR} adminmenu px-3 py-2`}
      >
        Category
      </NavLink>
      <NavLink
        to="/users"
        className={`text-decoration-none text-${theme.COLOR} adminmenu px-3 py-2`}
      >
        User
      </NavLink>
    </ListGroup>
  );
};

export default Sidebar;
