import { Card, Col, Container, Row } from "react-bootstrap";

import SideBar from "./SideBar";
import { useContext } from "react";
import ThemeSettingContext from "../../../helper/context/theme-settings/ThemeSettingContext";

const ProfileSidebar = ({ Component }) => {
  const { theme } = useContext(ThemeSettingContext); //set theme

  return (
    <>
      <Container fluid>
        <div className="">
          <Row>
            <Col
              xs={12}
              md={3}
              xl={2}
              className="p-0 border-right side-bar-menu d-flex"
            >
              <Card className={`w-100 bg-${theme.THEME}`}>
                <Card.Body className="p-0">
                  <SideBar />
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              md={9}
              xl={10}
              className="p-0 mb-2 right-box-section sidebar-width"
            >
              <Component />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ProfileSidebar;
