
import React from 'react'
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

//Invitation routes
const RecipientSettingsList = lazy(() => import("./list/RecipientSettingsList"));
const RecipientSettingsEdit = lazy(() => import("./edit/RecipientSettingsEdit"));
// const ChecklistAlertsCreate = lazy(() =>  import("./checklist-alert-create/ChecklistAlertsCreate"));
// const ChecklistAlertsEdit = lazy(() => import("./checklist-alert-edit/ChecklistAlertsEdit"));
// const ChecklistAlertView = lazy(() => import("./checklist-alert-view/ChecklistAlertView"));


const RecipientSettingsRoutes = () => {
  return (
  <>
      <Routes>
          <Route path="/" element={<EventDetails Component={RecipientSettingsList} />} />
          <Route
              path="/edit"
              element={<EventDetails Component={RecipientSettingsEdit} />}
          />
      </Routes>
    </>
  )
}

export default RecipientSettingsRoutes



