import { Card, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

const PageNotFound = () => {
  return (
    <>
      <Container>
        <div className="">
          <Row>
              <Col></Col>
              <Col xs={6}>
                <Card>
                  <Card.Header>Page not found</Card.Header>
                  <Card.Body className="text-center">
                    <h4>404</h4>
                    <p>The requested page does not exist.</p>
                    <Link to="/" className='btn btn-primary'>Back to home</Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col></Col>
          </Row>
        </div>
        
      </Container>
    </>
  )
}
export default PageNotFound
