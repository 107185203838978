import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const EmbeddedWidgetsList = lazy(() =>
  import("./embedded-widgets-list/EmbeddedWidgetsList")
);
const EmbeddedWidgetsCreate = lazy(() =>
  import("./embedded-widgets-create/EmbeddedWidgetsCreate")
);
const EmbeddedWidgetsEdit = lazy(() =>
  import("./embedded-widgets-edit/EmbeddedWidgetsEdit")
);


const EmbeddedWidgetsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={EmbeddedWidgetsList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={EmbeddedWidgetsCreate} />}
      />
      <Route
        path="/:weblinkSlug/edit"
        element={<EventDetails Component={EmbeddedWidgetsEdit} />}
      />
    </Routes>
  );
};

export default EmbeddedWidgetsRoutes
