import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const DocumentsList = lazy(() =>
  import("./documents-list/DocumentsList")
);
const DocumentsCreate = lazy(() =>
  import("./documents-create/DocumentsCreate")
);
const DocumentsEdit = lazy(() =>
  import("./documents-edit/DocumentsEdit")
);
const DocumentsView = lazy(() =>
  import("./documents-view/DocumentsView")
);

const DocumentsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={DocumentsList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={DocumentsCreate} />}
      />
       <Route
       path="/:documentSlug/edit"
        element={<EventDetails Component={DocumentsEdit} />}
      />
       <Route
        path="/:documentSlug/view"
        element={<EventDetails Component={DocumentsView} />}
      />
    </Routes>
  );
};


export default DocumentsRoutes