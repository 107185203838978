// ThemeSettingState.js

import { THEMETYPE, THEMETYPES } from "../../../constants/Constants";
import { DARKTHEME, LIGHTTHEME } from "./ThemeSetting";
import ThemeSettingContext from "./ThemeSettingContext";
import { useState } from "react";

const ThemeSettingState = ({ children }) => {
  // State to manage the current theme
  const [theme, setTheme] = useState(DARKTHEME);

  // Function to change the theme
  const changeTheme = (type) => {
    if (type === THEMETYPES.DARK) {
      // Set the dark theme and update the body background color
      setTheme(DARKTHEME);
      document.body.style.backgroundColor = DARKTHEME.BACKGROUNDCOLOR;

      // Save the theme type in local storage
      localStorage.setItem(THEMETYPE, btoa(type));
    } else if (type === THEMETYPES.LIGHT) {
      // Set the light theme and update the body background color
      setTheme(LIGHTTHEME);
      document.body.style.backgroundColor = LIGHTTHEME.BACKGROUNDCOLOR;

      // Save the theme type in local storage
      localStorage.setItem(THEMETYPE, btoa(type));
    }
  };

  return (
    // Provide the theme and changeTheme function to children components through context
    <ThemeSettingContext.Provider value={{ theme, changeTheme, setTheme }}>
      {children}
    </ThemeSettingContext.Provider>
  );
};

export default ThemeSettingState;