import { ListGroup } from "react-bootstrap";
import ThemeSettingContext from "../../../helper/context/theme-settings/ThemeSettingContext";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const SideBar = () => {
  const { theme } = useContext(ThemeSettingContext);
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleSubMenu = (menuName) => {
    setActiveMenu(isSubMenuOpen(menuName) ? null : menuName);
  };

  const isSubMenuOpen = (menuName) => {
    return activeMenu === menuName;
  };
  return (
    <ListGroup className="profile_menu">
      <div
        className={`text-decoration-none  px-3 py-2 f cursor-pointer ${
          isSubMenuOpen("general") ? "actived" : ""
        }`}
        onClick={() => toggleSubMenu("general")}
      >
        <div
          className={`d-flex justify-content-between align-items-center text-${theme.COLOR}`}
        >
          <span>General</span>
          <span>
            {isSubMenuOpen("general") ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
        </div>
        {isSubMenuOpen("general") && (
          <ListGroup>
            <NavLink
              to={`/admin/custom-fields`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Custom Fields
            </NavLink>
            <NavLink
              to={`/admin/custom-data-tags`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              Custom Data Tags
            </NavLink>
            <NavLink
              to={`/admin/general-ledger-codes`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
              General Ledger Codes
            </NavLink>
            <NavLink
              to={`/admin/answer-format`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
             Answer Formats
            </NavLink>
            <NavLink
              to={`/admin/contact-website`}
              className={`text-decoration-none text-${theme.COLOR} px-3 py-2`}
            >
             Contact Websites
            </NavLink>
          </ListGroup>
        )}
      </div>
      <NavLink
        to="/admin/profile"
        className={`text-decoration-none text-${theme.COLOR} px-3 py-2 `}
      >
        Profile
      </NavLink>

      <NavLink
        to="/admin/change-password"
        className={`text-decoration-none text-${theme.COLOR} adminmenu px-3 py-2`}
      >
        Change Password
      </NavLink>
    </ListGroup>
  );
};

export default SideBar;
