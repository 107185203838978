import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const OptionalItemsList = lazy(() =>
  import("./optional-items-list/OptionalItemsList")
);
const OptionalItemsCreate = lazy(() =>
  import("./optional-items-create/OptionalItemsCreate")
);
const OptionalItemsEdit = lazy(() =>
  import("./optional-items-edit/OptionalItemsEdit")
);
const OptionalItemsView = lazy(() =>
  import("./optional-items-view/OptionalItemsView")
);
const OptionalItemsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={OptionalItemsList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={OptionalItemsCreate} />}
      />
      <Route
        path="/:admissionSlug/edit"
        element={<EventDetails Component={OptionalItemsEdit} />}
      />
      <Route
        path="/:admissionSlug/view"
        element={<EventDetails Component={OptionalItemsView} />}
      />
    </Routes>
  );
};
export default OptionalItemsRoutes
