import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AdminSideBar from "../AdminSideBar";
import isAdminAuth from "../../../helper/middleware/isAdminAuth";
//Event Routes

const CategoryList = lazy(() =>
  import("./categories-list/CategoryList").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const CategoryCreate = lazy(() =>
  import("./category-create/CategoryCreate").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const CategoryEdit = lazy(() =>
  import("./category-edit/CategoryEdit").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);

const CategoriesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AdminSideBar Component={CategoryList}></AdminSideBar>}
      />
      <Route
        path="/create"
        element={<AdminSideBar Component={CategoryCreate}></AdminSideBar>}
      />
      <Route
        path="/edit/:id"
        element={<AdminSideBar Component={CategoryEdit}></AdminSideBar>}
      />
    </Routes>
  );
};
export default CategoriesRoutes;
