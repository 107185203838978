import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const EventSettings = lazy(() => import("./event-setting/EventSettings"));
const EditEventSettings = lazy(() =>
  import("./edit-event-settings/EditEventSettings")
);

const SettingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={EventSettings} />} />
      <Route
        path="/:settingSlug/edit-event-settings"
        element={<EventDetails Component={EditEventSettings} />}
      />
    </Routes>
  );
};

export default SettingRoutes;
