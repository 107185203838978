import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const AdmissionItemForm = lazy(() =>
  import("./registration-settings-form/RegistrationSettingsForm")
);
const AdmissionItemView = lazy(() =>
  import("./registration-settings-view/RegistrationSettingsView")
);

const RegistrationSettingsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={AdmissionItemView} />}
      />
      <Route
        path="/form"
        element={<EventDetails Component={AdmissionItemForm} />}
      />
    </Routes>
  );
};


export default RegistrationSettingsRoutes
