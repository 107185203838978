import {
  Container,
  Card,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import VerificationController from "./VerificationController";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
const Verification = () => {
  const { loader, theme, message, showButton, success, error } =
    VerificationController();
  return (
    <>
      <Container>
        <div className="outer-card-box justify-content-center align-items-center d-flex h-100vh">
          {loader ? (
            <Spinner animation="grow" variant="primary" />
          ) : (
            <Row className="w-100 justify-content-center">
              <Col xs={11} md={5} xl={5}>
                <Card className={`bg-${theme.THEME}`}>
                  <Card.Header>
                    {success && <span>Verification</span>}
                    {error && <span>Verification Error</span>}
                  </Card.Header>
                  <Card.Body className="text-center">
                    {success && (
                      <div className={"success_icon icon_design mb-2"}>
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </div>
                    )}
                    {error && (
                      <div className={"icon_design danger_icon mb-2"}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                      </div>
                    )}
                    {message}
                    {showButton && (
                      <div>
                        <Link
                          to={`/`}
                          className="btn btn-primary btn-theme-background mt-2"
                        >
                          Login
                        </Link>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </>
  );
};
export default Verification;
