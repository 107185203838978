import React, { useState } from 'react'
import AuthSettingContext from './AuthSettingContext'

const AuthSettingState = ({ children }) => {
  const [authName, setAuthName] =useState();
  return (
    <AuthSettingContext.Provider value={{authName,  setAuthName}}>
        {children}
    </AuthSettingContext.Provider>
  )
}
export default AuthSettingState
