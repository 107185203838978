import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const RegistrationProcess = lazy(() =>
  import("./RegistrationProcess")
);
const PersonalInformation = lazy(() =>
  import("./personalinformation/PersonalInformation")
);
const GuestInformation = lazy(() =>
  import("./guest-information/GuestInformation")
);
const DeclineForm = lazy(() =>
  import("./decline-form/DeclineForm")
);
const CancellationForm = lazy(() =>
  import("./cancellation-form/CancellationForm")
);
const RegistrationSummary = lazy(() =>
  import("./registration-summary/RegistrationSummary")
);
const Confirmation = lazy(() =>
  import("./confirmation/Confirmation")
);
const RegistrationRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={RegistrationProcess} />}
      />
      <Route path="/personal-information-form" element={<PersonalInformation />} />
      <Route path="/registration-summary-form" element={<RegistrationSummary />} />
      <Route path="/confirmation-form" element={<Confirmation />} />
      <Route path="/cancellation-form" element={<CancellationForm />} />
      <Route path="/decline-form" element={<DeclineForm />} />
      <Route path="/guest-information-form" element={<GuestInformation />} />
    </Routes>
  );
};

export default RegistrationRoutes;
