import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AdminSideBar from "../AdminSideBar";
import isAdminAuth from "../../../helper/middleware/isAdminAuth";
//Event Routes

const RoleList = lazy(() =>
  import("./role-list/Role").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const RoleCreate = lazy(() =>
  import("./role-create/RoleCreate").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const RoleEdit = lazy(() =>
  import("./role-edit/RoleEdit").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);

const RoleRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AdminSideBar Component={RoleList}></AdminSideBar>}
      />
      <Route
        path="/create"
        element={<AdminSideBar Component={RoleCreate}></AdminSideBar>}
      />
      <Route
        path="/edit/:id"
        element={<AdminSideBar Component={RoleEdit}></AdminSideBar>}
      />
    </Routes>
  );
};
export default RoleRoutes;
