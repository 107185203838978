import { createRefresh } from "react-auth-kit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const RefreshApi = createRefresh({
  interval: 1,
  refreshApiCallback: async ({ refreshToken }) => {
    // API container function
    try {
      await axios
        .post(`${API_URL}/refreshToken`, {
          refreshToken: refreshToken,
        })
        .then((response) => {
          if (response.data.success) {
            let resData = response.data.data;
            return {
              isSuccess: true,
              newAuthToken: resData.accessToken,
              newAuthTokenExpireIn: 160,
              newRefreshToken: refreshToken,
              newRefreshTokenExpiresIn: 180,
            };
          }
        });
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});

export default RefreshApi;
