import { useContext, useEffect, useState } from "react";
import { AuthProvider } from "react-auth-kit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterComponent from "./routers/RouterComponent";
import ThemeSettingContext from "./helper/context/theme-settings/ThemeSettingContext";
import { DARKTHEME, LIGHTTHEME } from "./helper/context/theme-settings/ThemeSetting";

import RefreshApi from "./pages/auth/login/RefreshApi";
import { THEMETYPE, THEMETYPES } from "./constants/Constants";

const App = () => {
  const { setTheme } = useContext(ThemeSettingContext);
  useEffect(() => {
    const getThemeType = localStorage.getItem(THEMETYPE);
    const themeType = atob(getThemeType);
    if (themeType !== undefined && themeType !== null) {
      if (themeType === THEMETYPES.DARK) {
        setTheme(DARKTHEME);
        document.body.style.backgroundColor = DARKTHEME.BACKGROUNDCOLOR;
      } else if (themeType === THEMETYPES.LIGHT) {
        setTheme(LIGHTTHEME);
        document.body.style.backgroundColor = LIGHTTHEME.BACKGROUNDCOLOR;
      } else {
        setTheme(LIGHTTHEME);
        document.body.style.backgroundColor = LIGHTTHEME.BACKGROUNDCOLOR;
      }
    } else {
      setTheme(LIGHTTHEME);
      document.body.style.backgroundColor = LIGHTTHEME.BACKGROUNDCOLOR;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ToastContainer className="fsf" />
      <AuthProvider
        authType={"cookie"}
        authName={"token"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
        refresh={RefreshApi}
      >
        <RouterComponent />
      </AuthProvider>
    </>
  );
};

export default App;
