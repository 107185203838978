
import ThemeSettingContext from "../../../../../helper/context/theme-settings/ThemeSettingContext";
import { useContext } from "react";


export const QuestionSummaryController = () => {
  const { theme } = useContext(ThemeSettingContext); //set theme

  

  


  return {
    theme,
  };
};

export default QuestionSummaryController
