import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  import faker from "faker";
  import ThemeSettingContext from "../../../../../helper/context/theme-settings/ThemeSettingContext";
  import { useContext } from "react";
  
  
  export const AgendaItemSummaryController = () => {
    const { theme } = useContext(ThemeSettingContext); //set theme
  
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  
    const registraionOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Registration",
        },
      },
    };
    const labels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    const registrationData = {
      labels,
      datasets: [
        {
          label: "Registration",
          data: labels.map(() =>
            faker.datatype.number({ min: 1, max: 1000 })
          ),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  
  
    return {
      registraionOptions,
      registrationData,
      theme,
    };
  };

export default AgendaItemSummaryController
