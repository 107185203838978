import { lazy } from "react";
import { Routes, Route,Navigate } from "react-router-dom";
import PrivateRoutes from './PrivateRoutes'
import Cookies from 'js-cookie';
const PersonalInformation = lazy(() => import("../personal-information/PersonalInformation"));
const RegistrationSummary = lazy(() => import("../registration-summary/RegistrationSummary"));
const Confirmation = lazy(() => import("../confirmation/Confirmation"));
const CancellationForm = lazy(() => import("../cancellation-form/CancellationForm"));
const DeclineForm = lazy(() => import("../decline-form/DeclineForm"));
const GuestInformation = lazy(() => import("../guest-information/GuestInformation"));
const Login = lazy(() => import("../auth/log-in/Login"));
const ForgotNumber = lazy(() => import("../auth/forgot-number/ForgotNumber"));
const RegistrationFormRoutes = () => {
  const auth = Cookies.get('user');
  return (
    <Routes>
      <Route path="/:slug/personal-information" element={<PersonalInformation />} />
      <Route path="/:slug/summary" element={<RegistrationSummary />} />
      <Route path="/:slug/confirmation" element={<PrivateRoutes Component={Confirmation} />} />
      <Route path="/:slug/cancellation" element={<CancellationForm />} />
      <Route path="/:slug/decline" element={<DeclineForm />} />
      <Route path="/:slug/guest-information" element={<GuestInformation />} />
      <Route path="/login" element={!auth? <Login />: <Navigate to="event/personal-information" />} />
      <Route path="/forgot" element={!auth? <ForgotNumber />: <Navigate to="event/personal-information" />} />
    </Routes>
  );
};
export default RegistrationFormRoutes;
