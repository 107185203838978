import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const StaffList = lazy(() =>
  import("./staff-list/StaffList")
);
const StaffCreate = lazy(() =>
  import("./staff-create/StaffCreate")
);
const StaffEdit = lazy(() =>
  import("./staff-edit/StaffEdit")
);
const StaffView = lazy(() =>
  import("./staff-view/StaffView")
);

const StaffRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={StaffList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={StaffCreate} />}
      />
       <Route
       path="/:staffSlug/edit"
        element={<EventDetails Component={StaffEdit} />}
      />
       <Route
        path="/:staffSlug/view"
        element={<EventDetails Component={StaffView} />}
      />
    </Routes>
  );
};


export default StaffRoutes