import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSidebar from "./ProfileSidebar";
import CustomFieldsRoutes from "./general/custom-fields/CustomFieldsRoutes";
import CustomDataTagsRoutes from "./general/custom-data-tags/CustomDataTagsRoutes";
import GeneralLedgerCodesRoutes from "./general/general-ledger-codes/GeneralLedgerCodesRoutes";
import AnswerFormatRoutes from "./general/answer-formats/AnswerFormatRoutes";
import ContactWebsiteRoutes from "./general/contact-websites/ContactWebsiteRoutes";
//Event Routes

const Profile = lazy(() =>
  import("../update-details/profile-update/ProfileUpdate")
);
const ChangePassword = lazy(() =>
  import("../update-details/change-password/ChangePassword")
);

const ProfileRouters = () => {
  return (
    <Routes>
      <Route
        path="/custom-fields/*"
        element={<CustomFieldsRoutes/>}
      />
      <Route
        path="/custom-data-tags/*"
        element={<CustomDataTagsRoutes/>}
      />
      <Route
        path="/general-ledger-codes/*"
        element={<GeneralLedgerCodesRoutes/>}
      />
      <Route
        path="/answer-format/*"
        element={<AnswerFormatRoutes/>}
      />
       <Route
        path="/contact-website/*"
        element={<ContactWebsiteRoutes/>}
      />
      <Route
        path="/profile"
        element={<ProfileSidebar Component={Profile}></ProfileSidebar>}
      />
      <Route
        path="/change-password"
        element={<ProfileSidebar Component={ChangePassword}></ProfileSidebar>}
      />
    </Routes>
  );
};
export default ProfileRouters;
