import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSidebar from "../../ProfileSidebar";

const ContactWebsiteList = lazy(() => import("./constact-website-list/ContactWebsiteList"));
const ContactWebsiteCreate = lazy(() => import("./constact-website-create/ContactWebsiteCreate"));
const ContactWebsiteEdit = lazy(() => import("./constact-website-edit/ContactWebsiteEdit"));
const ContactWebsiteView = lazy(() => import("./constact-website-view/ContactWebsiteView"));

const ContactWebsiteRoutes = () => {
    return (
        <Routes>
          <Route
            path="/"
            element={<ProfileSidebar Component={ContactWebsiteList}></ProfileSidebar>}
          />
            <Route
            path="/create"
            element={<ProfileSidebar Component={ContactWebsiteCreate}></ProfileSidebar>}
          />
          <Route
            path="/edit/:id"
            element={<ProfileSidebar Component={ContactWebsiteEdit}></ProfileSidebar>}
          />
          <Route
            path="/view/:id"
            element={<ProfileSidebar Component={ContactWebsiteView}></ProfileSidebar>}
          />
        </Routes>
      );
}

export default ContactWebsiteRoutes
