import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSidebar from "../../ProfileSidebar";


const GeneralLedgerCodesList = lazy(() =>
  import("./general-ledger-codes-list/GeneralLedgerCodesList")
);
const GeneralLedgerCodesCreate = lazy(() =>
  import("./general-ledger-codes-create/GeneralLedgerCodesCreate")
);
const GeneralLedgerCodesEdit = lazy(() =>
  import("./general-ledger-codes-edit/GeneralLedgerCodesEdit")
);
const GeneralLedgerCodesView = lazy(() =>
  import("./general-ledger-codes-view/GeneralLedgerCodesView")
);


const GeneralLedgerCodesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProfileSidebar Component={GeneralLedgerCodesList} />}
      />
       <Route
        path="/create"
        element={<ProfileSidebar Component={GeneralLedgerCodesCreate} />}
      />
       <Route
        path="/edit/:slug"
        element={<ProfileSidebar Component={GeneralLedgerCodesEdit} />}
      />
       <Route
        path="/view/:slug"
        element={<ProfileSidebar Component={GeneralLedgerCodesView} />}
      />
    
    </Routes>
  );
};

export default GeneralLedgerCodesRoutes
