import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

//Invitation routes
const InvitationList = lazy(() => import("./invitation-list/invitationList"));
const InvitationCreate = lazy(() =>
  import("./invitation-create/invitationCreate")
);

const InvitationEdit = lazy(() => import("./invitation-edit/invitationEdit"));

const InvitationView = lazy(() => import("./invitation-view/invitationView"));

//END

const InvitationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={InvitationList} />} />
      <Route
        path="/create-invitation"
        element={<EventDetails Component={InvitationCreate} />}
      />
      <Route
        path="/:invitationSlug/edit-invitation"
        element={<EventDetails Component={InvitationEdit} />}
      />
      <Route
        path="/:invitationSlug/view-invitation"
        element={<EventDetails Component={InvitationView} />}
      />
    </Routes>
  );
};

export default InvitationRoutes;
