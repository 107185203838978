import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const SessionBundlesList = lazy(() =>
  import("./session-bundles-list/SessionBundlesList")
);
const SessionBundlesCreate = lazy(() =>
  import("./session-bundles-create/SessionBundlesCreate")
);
const SessionBundlesEdit = lazy(() =>
  import("./session-bundles-edit/SessionBundlesEdit")
);
const SessionBundlesView = lazy(() =>
  import("./session-bundles-view/SessionBundlesView")
);

const SessionBundlesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={SessionBundlesList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={SessionBundlesCreate} />}
      />
      <Route
        path="/:bundlesSlug/edit"
        element={<EventDetails Component={SessionBundlesEdit} />}
      />
      <Route
        path="/:bundlesSlug/view"
        element={<EventDetails Component={SessionBundlesView} />}
      />
    </Routes>
  );
};

export default SessionBundlesRoutes;