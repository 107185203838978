import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import HeaderController from "./HeaderController";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeWords } from "../../helper/CommonFunction";
const Header = () => {
  const { signOut, changeTheme, theme, THEMETYPES, authName } = HeaderController(); //Logic
 
  return (
    <Navbar
      expand="lg"
      className="menu-list"
      bg={theme.THEME}
      data-bs-theme={theme.THEME}
    >
      <Container fluid>
        <Link to="/events" className="navbar-brand">
          Event management
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 nav_bar"
            style={{ maxHeight: "200px" }}
            navbarScroll
          >
            <NavLink
              to="/events"
              className={`py-2 px-2 text-decoration-none text-${theme.COLOR}`}
            >
              All Events
            </NavLink>
          </Nav>
          <div className="d-flex align-items-center">
            <div
              className={` cursor-pointer`}
              onClick={() =>
                changeTheme(
                  theme.THEME === THEMETYPES.DARK
                    ? THEMETYPES.LIGHT
                    : THEMETYPES.DARK
                )
              }
            >
              {theme.THEME === THEMETYPES.DARK ? (
                <img src="/images/sun-bright.svg" className={` me-3`} alt="dark"/>
              ) : (
                <FontAwesomeIcon icon={faMoon} className={` me-3`} />
              )}
            </div>
            <NavDropdown
              title={capitalizeWords(authName??'')}
              id="navbarScrollingDropdown"
              className={`profile_dropdown text-${theme.COLOR}`}
            >
              <NavLink
                to="/admin/profile"
                className={`px-2 text-decoration-none text-${theme.COLOR}`}
              >
                Profile
              </NavLink>
              <NavDropdown.Item
                href="#"
                className={`px-2 text-${theme.COLOR}`}
                onClick={signOut}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
