import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AdminSideBar from "../AdminSideBar";
import isAdminAuth from "../../../helper/middleware/isAdminAuth";
//Event Routes

const UserList = lazy(() =>
  import("./user-list/UserList").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const UserCreate = lazy(() =>
  import("./user-create/UserCreate").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const UserEdit = lazy(() =>
  import("./user-edit/UserEdit").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const PersonalDetails = lazy(() =>
  import("./user-details/personal-details/PersonalDetails").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const Event = lazy(() =>
  import("./user-details/event/Event").then((module) => ({
    default: isAdminAuth(module.default),
  }))
);
const UserssRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AdminSideBar Component={UserList}/>}
      />
      <Route
        path="/create"
        element={<AdminSideBar Component={UserCreate}/>}
      />
      <Route
        path="/edit/:id"
        element={<AdminSideBar Component={UserEdit}/>}
      />
      <Route
      path="/details/:id" element={<AdminSideBar Component={PersonalDetails}/>}/>
      <Route
      path="/events/:id" element={<AdminSideBar Component={Event}/>}/>
    </Routes>
  );
};
export default UserssRoutes;
