import { useContext, useEffect } from "react";
import ThemeSettingContext from "../../helper/context/theme-settings/ThemeSettingContext";
import { THEMETYPES } from "../../constants/Constants";
import { useAuthUser, useSignOut } from "react-auth-kit";
import AuthSettingContext from "../../helper/context/auth-settings/AuthSettingContext";

const HeaderController = () => {
  const signOut = useSignOut();
  const auth = useAuthUser();
  const {authName,  setAuthName} = useContext(AuthSettingContext)
  const { theme, changeTheme } = useContext(ThemeSettingContext); //set theme
  useEffect(()=>{
    setAuthName(auth()?.name??'')   // eslint-disable-next-line
  },[])

  return { signOut, theme, changeTheme, THEMETYPES, authName };
};

export default HeaderController;
