import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import Select from "react-select";
import AgendaItemSummaryController from './AgendaItemSummaryController';
import { Line } from 'react-chartjs-2';

const AgendaItemSummary = () => {
  const {
    registraionOptions,
    registrationData,
    theme} = AgendaItemSummaryController()
  return (
    <Card className={`bg-${theme.THEME}`}>
      <Card.Header>
        <div className={`d-flex justify-content-between text-${theme.COLOR}`}>
          <div>Agenda Item Summary</div>
          
        </div>
      </Card.Header>
      <Card.Body>
        <div className="white-box">
          <Card.Title className="mb-3"> Filters</Card.Title>
          <Row>
            <Col xs={12} md={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label className={`text-${theme.COLOR}`}>View</Form.Label>
                <Select
                    options={[]}
                    isSearchable
                    // value={formField.category}
                    // onChange={(e) => handleChange(e, 'category')}
                    className={`bg-${theme.BACKGROUD} text-${theme.COLOR} w-100`}
                    placeholder="Select View"
                    isClearable
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label className={`text-${theme.COLOR}`}>By</Form.Label>
                <Select
                    options={[]}
                    isSearchable
                    // value={formField.category}
                    // onChange={(e) => handleChange(e, 'category')}
                    className={`bg-${theme.BACKGROUD} text-${theme.COLOR} w-100`}
                    placeholder="Select By"
                    isClearable
                />
              </Form.Group>
            </Col>
          </Row>
          <Card.Title className="mb-3"> Chart</Card.Title>
          <Row>
          <Col xs={12} md={12} xl={12}>
            <Line options={registraionOptions} data={registrationData} />
          </Col>
          </Row>
          <Card.Title className="mb-3">Table</Card.Title>
          <Row>
            <Col xs={12} md={12} xl={12}>
              <Table  bordered >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Type</th>
                    <th>Start Date</th>
                    <th>Capacity</th>
                    <th>Registered</th>
                    <th>Waitlisted</th>
                    <th>Attended</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={8} className='text-center'>No Data Found</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  )
}
export default AgendaItemSummary
