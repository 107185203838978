import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "./event-details/EventDetails";
import SpeakerRoutes from "./event-details/speakers/SpeakerRoutes";
import CustomDataRoutes from "./event-details/marketing/custom-Data/CustomDataRoutes";
import InvitationRoutes from "./event-details/email/invitation-List/InvitationRoutes";
import EmailRoutes from "./event-details/email/event-Emails/EmailRoutes";
import SessionRoutes from "./event-details/agenda/session-Lists/SessionRoutes";
import SessionLocationRoutes from "./event-details/agenda/session-location/SessionLocationRoutes";
import EventInfoRoutes from "./event-details/general/event-information/EventInfoRoutes";
import SettingRoutes from "./event-details/general/event-settings/SettingRoutes";
import AdminSideBar from "../admin/AdminSideBar";
import RegistrationRoutes from "./event-details/registration/registration-process/RegistrationRoutes";
import SessionGroupRoutes from "./event-details/agenda/session-groups/SessionGroupRoutes";
import SessionEmailRoutes from "./event-details/email/session-Emails/SessionEmailRoutes";
import SessionBundlesRoutes from "./event-details/agenda/session-bundles/SessionBundlesRoutes";
import WeblinksRoutes from "./event-details/marketing/weblinks/WeblinksRoutes";
import EmbeddedWidgetsRoutes from "./event-details/marketing/embedded-widgets/EmbeddedWidgetsRoutes";
import AdvancedRulesRoutes from "./event-details/registration/advanced-rules/AdvancedRulesRoutes";
import AdmissionItemRoutes from "./event-details/registration/admission-Item/AdmissionItemRoutes";
import PlannerAlertRoutes from "./event-details/email/planner-Alert/PlannerAlertRoutes";
import ChecklistAlertRoutes from "./event-details/email/registrant-checklist-alert/ChecklistAlertRoutes";
import RecipientSettingsRoutes from "./event-details/email/recipient-settings/RecipientSettingsRoutes";
import AttendeesRoutes from "./event-details/attendees/attendees-list/AttendeesRoutes";
import AbandoneRoutes from "./event-details/attendees/abandoned-registrations/AbandoneRoutes";
import BadgesRoutes from "./event-details/attendees/badges-certificates/BadgesRoutes";
import RegistrationSettingsRoutes from "./event-details/registration/registration-settings/RegistrationSettingsRoutes";
import OptionalItemsRoutes from "./event-details/registration/optional-items/OptionalItemsRoutes";
import OnArrivalSettingsRoutes from "./event-details/onarrival/on-arrival-settings/OnArrivalSettingsRoutes";
import FeedbackSurveyRoutes from "./event-details/surveys/feedback-survey/FeedbackSurveyRoutes";
import ResponseRoutes from "./event-details/surveys/responses-list/ResponsesRoutes";

import DocumentsRoutes from "./event-details/Planning/documents/DocumentsRoutes";
import StaffRoutes from "./event-details/Planning/staff/StaffRoutes";
import SeatingRoutes from "./event-details/Planning/seating/SeatingRoutes";
import ReportsRoutes from "./event-details/reports/ReportsRoutes";

const EventDashboard = lazy(() =>
  import("./event-details/event-dashboard/EventDashboard")
);

const DetailsRegistrationTypes = lazy(() =>
  import("./event-details/general/registration-types/DetailsRegistrationType")
);
const EditRegistrationTypeDetails = lazy(() =>
  import(
    "./event-details/general/registration-types/details/EditRegistrationTypeDetails"
  )
);

const EditRegistrationTypes = lazy(() =>
  import("./event-details/general/registration-types/EditRegistrationTypes")
);

const RegistrationTypes = lazy(() =>
  import("./event-details/general/registration-types/RegistrationTypes")
);

const EventFeature = lazy(() =>
  import("./event-details/general/features/EventFeature")
);
//END
// LangManagement
const LangManagement = lazy(() =>
  import("./event-details/marketing/langManagement-list/langManagementList")
);
// End

const EventList = lazy(() => import("./events/event-list/EventList"));
const EventCreate = lazy(() => import("./events/event-create/EventCreate"));

const EventRoutes = () => {
  return (
    <Routes>
      {/* Event Routes */}
      <Route
        path="/:slug/dashboard"
        element={<EventDetails Component={EventDashboard} />}
      />
      <Route
        path="/"
        element={<AdminSideBar Component={EventList}></AdminSideBar>}
      />
      <Route path="/create" element={<EventCreate />} />
      <Route path="/:slug/*" element={<EventInfoRoutes />} />
      <Route
        path="/:slug/general/features"
        element={<EventDetails Component={EventFeature} />}
      />
      <Route
        path="/:slug/registration-types"
        element={<EventDetails Component={RegistrationTypes} />}
      />
      <Route path="/:slug/event-settings/*" element={<SettingRoutes />} />
      {/* END */}
      {/* Attendees Routes */}
      <Route path="/:slug/attendees/*" element={<AttendeesRoutes />} />
      <Route path="/:slug/abandoned/*" element={<AbandoneRoutes />} />
      <Route path="/:slug/badges/*" element={<BadgesRoutes />} />
      {/* End */}
      {/* Speaker Routes */}
      <Route path="/:slug/speakers/*" element={<SpeakerRoutes />} />
      {/* Marketing routes */}
      <Route path="/:slug/custom-data/*" element={<CustomDataRoutes />} />
      <Route
        path="/:slug/lang-management"
        element={<EventDetails Component={LangManagement} />}
      />
      {/* END */}
      {/* Emails routes */}
      <Route path="/:slug/invitation/*" element={<InvitationRoutes />} />
      <Route path="/:slug/email-invitation/*" element={<EmailRoutes />} />
      <Route
        path="/"
        element={<AdminSideBar Component={EventList}></AdminSideBar>}
      />
      <Route path="/create" element={<EventCreate />} />
      {/* END */}
      <Route path="/:slug/*" element={<EventInfoRoutes />} />
      <Route
        path="/:slug/general/features"
        element={<EventDetails Component={EventFeature} />}
      />
      {/* Register Types routes */}
      <Route
        path="/registration-types-details/index/view/:slug/:typeId"
        element={<EventDetails Component={DetailsRegistrationTypes} />}
      />
      <Route
        path="/registration-types-details/index/edit/:slug/:typeId"
        element={<EventDetails Component={EditRegistrationTypeDetails} />}
      />
      <Route
        path="/:slug/registration-types/edit"
        element={<EventDetails Component={EditRegistrationTypes} />}
      />
      <Route
        path="/:slug/registration-types"
        element={<EventDetails Component={RegistrationTypes} />}
      />
      {/* End routes */}
      <Route path="/:slug/event-settings/*" element={<SettingRoutes />} />
      <Route path="/:slug/sessionemail/*" element={<SessionEmailRoutes />} />
      <Route path="/:slug/planner-email/*" element={<PlannerAlertRoutes />} />
      <Route
        path="/:slug/checklist-alert/*"
        element={<ChecklistAlertRoutes />}
      />
      <Route
        path="/:slug/emails/recipient-settings/*"
        element={<RecipientSettingsRoutes />}
      />
      {/* END */}
      {/* Session routes */}
      <Route
        path="/:slug/session-location/*"
        element={<SessionLocationRoutes />}
      />{" "}
      <Route path="/:slug/sessionlist/*" element={<SessionRoutes />} />{" "}
      <Route path="/:slug/session-groups/*" element={<SessionGroupRoutes />} />{" "}
      {/* Session Bundle  */}
      <Route
        path="/:slug/session-bundles/*"
        element={<SessionBundlesRoutes />}
      />
      {/* END */}
      {/*Registration Routes  */}
      <Route
        path="/:slug/registration-process/*"
        element={<RegistrationRoutes />}
      />
      {/* End */}
      {/* Weblinks */}
      <Route path="/:slug/web-links/*" element={<WeblinksRoutes />} />
      {/* End */}
      {/* Embedded Widgets */}
      <Route
        path="/:slug/embedded-widgets/*"
        element={<EmbeddedWidgetsRoutes />}
      />
      {/* End */}
      {/* advanced rules */}
      <Route path="/:slug/advanced-rules/*" element={<AdvancedRulesRoutes />} />
      {/* End */}
      {/*Admission Items */}
      <Route
        path="/:slug/admission-items/*"
        element={<AdmissionItemRoutes />}
      />
      {/* End */}
      {/*Registration Settings Routes */}
      <Route
        path="/:slug/registration-settings/*"
        element={<RegistrationSettingsRoutes />}
      />
      <Route path="/:slug/optional-items/*" element={<OptionalItemsRoutes />} />
      <Route
        path="/:slug/on-arrival-settings/*"
        element={<OnArrivalSettingsRoutes />}
      />
      {/* End */}
      {/* Surveys Routes */}
      <Route path="/:slug/survey/*" element={<FeedbackSurveyRoutes />} />
      <Route path="/:slug/response/*" element={<ResponseRoutes />} />
      {/*Documents  Routes */}
      <Route path="/:slug/documents/*" element={<DocumentsRoutes />} />
      <Route path="/:slug/staffs/*" element={<StaffRoutes />} />
      {/* End */}
      {/*Seating Routes   */}
      <Route path="/:slug/seatings/*" element={<SeatingRoutes />} />
      {/* End */}
      {/*Seating Routes   */}
      <Route
        path="/:slug/reports/*"
        element={<ReportsRoutes/>}
      />
      {/* End */}
    </Routes>
  );
};
export default EventRoutes;
