import { useContext, useState } from "react";
import ThemeSettingContext from "../../../../helper/context/theme-settings/ThemeSettingContext";

const SideBarController = () => {
  const { theme } = useContext(ThemeSettingContext); //set theme

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleSubMenu = (menuName) => {
    setActiveMenu(isSubMenuOpen(menuName) ? null : menuName);
  };

  const isSubMenuOpen = (menuName) => {
    return activeMenu === menuName;
  };
  return { toggleSubMenu, isSubMenuOpen, theme };
};

export default SideBarController;
