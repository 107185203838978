import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../EventDetails";
const SpeakerList = lazy(() => import("./list/SpeakerList"));
const CreateSpeaker = lazy(() => import("./create/CreateSpeaker"));
const ViewSpeaker = lazy(() => import("./view/ViewSpeaker"));
const EditSpeaker = lazy(() => import("./edit/EditSpeaker"));

const SpeakerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={SpeakerList} />} />
      <Route
        path="/create"
        element={<EventDetails Component={CreateSpeaker} />}
      />
      <Route
        path="/view/:speaker_slug"
        element={<EventDetails Component={ViewSpeaker} />}
      />
      <Route
        path="/edit/:speaker_slug"
        element={<EventDetails Component={EditSpeaker} />}
      />
    </Routes>
  );
};

export default SpeakerRoutes;
