import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../EventDetails";
import QuestionSummary from "./question-summary/QuestionSummary";
import AgendaItemSummary from "./agenda-item-summary/AgendaItemSummary";
const InviteeSummary = lazy(() =>
  import("./invitee-summary/InviteeSummary")
);


const ReportsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/invitee-summary"
        element={<EventDetails Component={InviteeSummary} />}
      />
      <Route
        path="/question-summary"
        element={<EventDetails Component={QuestionSummary} />}
      />
      <Route
        path="/agenda-item-summary"
        element={<EventDetails Component={AgendaItemSummary} />}
      />
    </Routes>
  );
};

export default ReportsRoutes
