
import { Row, Col } from 'react-bootstrap'
import { useContext } from "react";
import ThemeSettingContext from "../../helper/context/theme-settings/ThemeSettingContext";
const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { theme } = useContext(ThemeSettingContext); //set theme
  return (
    <div className={`text-center position-fixed bottom-0 start-0 end-0    bg-${theme.THEME}`}>
       <Row>
        <Col
          md={12}
          lg={12}
          xs={12}
        >
          <div className="text-center">
            <p className={`mb-0 py-1 text-${theme.COLOR}`}>
              Copyright © {currentYear} event management, All rights
              reserved.
            </p>
          </div>
        </Col>
      </Row>
    
    </div>);
};

export default Footer;
