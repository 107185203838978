import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { StyleSheetManager } from "styled-components";
import RootStateContext from "./helper/context/RootStateContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <StyleSheetManager shouldForwardProp={(prop) => prop !== "align"}>
    <RootStateContext>
      <App />
    </RootStateContext>
  </StyleSheetManager>
  // </React.StrictMode>
  
);
