import React from 'react'
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

//Invitation routes
const PlannerList = lazy(() => import("./planner-list/plannerList"));
const PlannerCreate = lazy(() => import("./planner-create/plannerCreate"));
const PlannerEdit = lazy(() => import("./planner-edit/plannerEdit"));
const PlannerView = lazy(() => import("./planner-view/plannerView"));
const PlannerAlertRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<EventDetails Component={PlannerList} />} />
                <Route
                    path="/create"
                    element={<EventDetails Component={PlannerCreate} />}
                />
                 <Route
                    path="/:plannerSlug/edit"
                    element={<EventDetails Component={PlannerEdit} />}
                />
                <Route
                    path="/:plannerSlug/view"
                    element={<EventDetails Component={PlannerView} />}
                />
            </Routes>
        </>
    )
}

export default PlannerAlertRoutes


