import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const ResponsesList = lazy(() => import("./ResponsesList"));

const ResponseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={ResponsesList} />} />
    </Routes>
  );
};

export default ResponseRoutes;
