import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSidebar from "../../ProfileSidebar";


const CustomDataTagsList = lazy(() =>
  import("./custom-data-tags-list/CustomDataTagsList")
);
const CustomDataTagsCreate = lazy(() =>
  import("./custom-data-tags-create/CustomDataTagsCreate")
);
const CustomDataTagsEdit = lazy(() =>
  import("./custom-data-tags-edit/CustomDataTagsEdit")
);
const CustomDataTagsView = lazy(() =>
  import("./custom-data-tags-view/CustomDataTagsView")
);


const CustomDataTagsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProfileSidebar Component={CustomDataTagsList} />}
      />
       <Route
        path="/create"
        element={<ProfileSidebar Component={CustomDataTagsCreate} />}
      />
       <Route
        path="/edit/:slug"
        element={<ProfileSidebar Component={CustomDataTagsEdit} />}
      />
       <Route
        path="/view/:slug"
        element={<ProfileSidebar Component={CustomDataTagsView} />}
      />
    
    </Routes>
  );
};

export default CustomDataTagsRoutes
