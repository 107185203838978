import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "../../../api/Api";
import { STATUSCODE } from "../../../constants/Constants";
import ThemeSettingContext from "../../../helper/context/theme-settings/ThemeSettingContext";

const VerificationController = () => {
  // Get verification token from URL parameters
  const [searchParams] = useSearchParams();
  const verificationToken = searchParams.get("token");
  // End
  // For API
  const api = useApi();
  // End

  const { theme } = useContext(ThemeSettingContext); // Set theme

  const [loader, setLoader] = useState(false); // Loader state
  const [message, setMessage] = useState(""); // Message state
  const [showButton, setShowButton] = useState(false); // Button state
  const [success, setsuccess] = useState(false); // Button state
  const [error, seterror] = useState(false); // Button state

  useEffect(() => {
    // Call the verification function on component mount
    verification();
    // eslint-disable-next-line
  }, []);

  // Verification function to verify the token
  const verification = async () => {
    setLoader(true);
    const body = {
      token: verificationToken,
    };
    try {
      await api
        .post(`/userverify`, body)
        .then((response) => {
          let resData = response.data;
          if (resData.status === STATUSCODE.HTTPOK) {
            // Show success message and set state to show the button
            setMessage(resData.message);
            setShowButton(true);
            setsuccess(true);
          } else if (resData.status === STATUSCODE.HTTPVALIDATE) {
            // Show error messages for validation errors and set the message state
            resData.message.forEach((errorMessage) => {
              setMessage(errorMessage);
            });
            seterror(true);
            setsuccess(false);
          } else if (resData.status === STATUSCODE.HTTPNOTFOUND) {
            // Show error message for not found status and set the message state

            seterror(true);
            setsuccess(false);
          } else if (resData.status === STATUSCODE.HTTPBADREQUEST) {
            // Show error message for bad request status and set the message state

            setMessage(resData.message);
            seterror(true);
            setsuccess(false);
          } else if (resData.status === STATUSCODE.HTTPINTERNALSERVERERROR) {
            // Show error message for internal server error and set the message state

            setMessage(resData.message);
            seterror(true);
            setsuccess(false);
          } else {
            // Show error message for other status codes and set the message state

            setMessage(resData.message);
            seterror(true);
            setsuccess(false);
          }
        })
        .catch((error) => {
          // Show error message for other errors and set the message state

          setMessage(error.message);
          seterror(true);
          setsuccess(false);
        });
    } catch (error) {
      // Show error message for other errors and set the message state

      seterror(true);
      setsuccess(false);
    } finally {
      setLoader(false);
    }
  };
  // End

  return { loader, theme, message, showButton, success, error };
};

export default VerificationController;
