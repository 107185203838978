import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
//Marketing routes
const CustomData = lazy(() => import("./customData-list/customDataList"));

const CreateCustomData = lazy(() =>
  import("./customData-create/customDataCreate")
);

const EditCustomData = lazy(() => import("./customData-edit/customDataEdit"));

const ViewCustomData = lazy(() => import("./customData-view/customDataView"));

//END

const CustomDataRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={CustomData} />} />
      <Route
        path="/create-custom-data"
        element={<EventDetails Component={CreateCustomData} />}
      />
      <Route
        path="/:customDataSlug/edit-custom-data"
        element={<EventDetails Component={EditCustomData} />}
      />
      <Route
        path="/:customDataSlug/view-custom-data"
        element={<EventDetails Component={ViewCustomData} />}
      />
    </Routes>
  );
};

export default CustomDataRoutes;
