import { Card, Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import ThemeSettingContext from "../../helper/context/theme-settings/ThemeSettingContext";
import Sidebar from "./Sidebar";
import { useAuthUser } from "react-auth-kit";

const AdminSideBar = ({ Component }) => {
  const { theme } = useContext(ThemeSettingContext); //set theme
  const [showSideBar, setShowSidebar] = useState(false);
  const auth = useAuthUser();
  useEffect(() => {
    if (auth().role === "admin") {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container fluid>
        <div className="">
          <Row>
            {showSideBar && (
              <Col
                xs={12}
                md={3}
                xl={2}
                className="p-0 border-right side-bar-menu d-flex"
              >
                <Card className={`w-100 bg-${theme.THEME}`}>
                  <Card.Body className="p-0">
                    <Sidebar />
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col
              xs={showSideBar ? 12 : 12}
              md={showSideBar ? 9 : 12}
              xl={showSideBar ? 10 : 12}
              className={`p-0 mb-2 right-box-section ${
                showSideBar ? "sidebar-width" : ""
              }`}
            >
              <Component />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default AdminSideBar;
