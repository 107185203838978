import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSidebar from "../../ProfileSidebar";

const AnswerList = lazy(() => import("./answer-list/AnswerList"));
const AnswerCreate = lazy(() => import("./answer-create/AnswerCreate"));
const AnswerEdit = lazy(() => import("./answer-edit/AnswerEdit"));
const AnswerView = lazy(() => import("./answer-view/AnswerView"));

const AnswerFormatRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProfileSidebar Component={AnswerList}></ProfileSidebar>}
      />
      <Route
        path="/create"
        element={<ProfileSidebar Component={AnswerCreate}></ProfileSidebar>}
      />
      <Route
        path="/edit/:id"
        element={<ProfileSidebar Component={AnswerEdit}></ProfileSidebar>}
      />
      <Route
        path="/view/:id"
        element={<ProfileSidebar Component={AnswerView}></ProfileSidebar>}
      />
    </Routes>
  );
};

export default AnswerFormatRoutes;
