
import React from 'react'
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

//Invitation routes
const ChecklistAlertList = lazy(() => import("./checklist-alert-list/ChecklistAlertList"));
const ChecklistAlertsCreate = lazy(() =>  import("./checklist-alert-create/ChecklistAlertsCreate"));
const ChecklistAlertsEdit = lazy(() => import("./checklist-alert-edit/ChecklistAlertsEdit"));
const ChecklistAlertView = lazy(() => import("./checklist-alert-view/ChecklistAlertView"));


const ChecklistAlertRoutes = () => {
  return (
  <>
      <Routes>
          <Route path="/" element={<EventDetails Component={ChecklistAlertList} />} />
          <Route
              path="/create"
              element={<EventDetails Component={ChecklistAlertsCreate} />}
          />
         <Route
              path="/edit"
              element={<EventDetails Component={ChecklistAlertsEdit} />}
          />
           <Route
                path="/view"
              // path="/:emailSlug/view-sessionemail"
              element={<EventDetails Component={ChecklistAlertView} />}
          />
      </Routes>
    </>
  )
}

export default ChecklistAlertRoutes



