import React from "react";
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const AbandonedList = lazy(() => import("./abandoned-list/AbandonedList"));

const AbandoneRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={AbandonedList} />} />
    </Routes>
  );
};

export default AbandoneRoutes;
