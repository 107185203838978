import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const OnArrivalSettingsEdit = lazy(() =>
  import("./on-arrival-settings-edit/OnArrivalSettingsEdit")
);
const OnArrivalSettingsView = lazy(() =>
  import("./on-arrival-settings-view/OnArrivalSettingsView")
);

const OnArrivalSettingsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/edit"
        element={<EventDetails Component={OnArrivalSettingsEdit} />}
      />
      <Route
        path="/"
        element={<EventDetails Component={OnArrivalSettingsView} />}
      />
    </Routes>
  );
};


export default OnArrivalSettingsRoutes