export const THEMETYPE = "tType"; //Theme type
export const MENUNAME = "menuName";
export const THEMETYPES = {
  LIGHT: "light",
  DARK: "dark",
};
// status code
export const STATUSCODE = {
  HTTPOK: 200,
  HTTPCREATED: 201,
  HTTPNOCONTENT: 204,
  HTTPNOTMODIFIED: 304,
  HTTPBADREQUEST: 400,
  HTTPUNAUTHORIZED: 401,
  HTTPFORBIDDEN: 403,
  HTTPNOTFOUND: 404,
  HTTPMETHODNOTALLOWED: 405,
  HTTPNOTACCEPTABLE: 406,
  HTTPCONFLICT: 409,
  HTTPINTERNALSERVERERROR: 500,
  HTTPNOTIMPLEMENTED: 501,
  HTTPEXCEPTION: 300,
  HTTPVALIDATE: 422,
  HTTPEXPIRED:410
};
// End
// For Status
export const STATUS = {
  ACTIVE: "1",
  INACTIVE: "2",
  ACTIVEINTERGER:1,
  INACTIVEINTERGER:2
};
// End
// Status list
export const STATUSLIST = [
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];
// End
// Status list
export const EVENTSTATUSLIST = [
  { value: 1, label: "Upcoming" },
  { value: 2, label: "Running" },
  { value: 3, label: "Completed" },
];
// End
// Event format
export const EVENTFORMAT = {
  INPERSON: "1",
  VIRTUAL: "2",
  HYBRID: "3",
};
// End
// Event format
export const EVENTSTATUS = {
  UPCOMING: "1",
  RUNNING: "2",
  COMPLETED: "3",
};
// End
//Session type
export const SESSIONTYPE = {
  INCLUDED: 1,
  OPTIONAL: 2,
};
export const SESSIONTYPELIST = [
  { value: 1, label: "Included" },
  { value: 2, label: "Optional" },
];
// End
// file size
export const FILESIZE = 5 * 1024 * 1024;
// End
// form  type
export const FORMTYPE = {
  PERSONALINFORMATION: 1,
  GUESTINFORMATION: 2,
  DECLINE: 3,
  CANCELLATION: 4,
  REGISTRATION: 5,
  CONFIRMATION: 6,
};
// End
// Submit Type
export const SUBMITTYPE ={
  SAVE : 1,
  PUBLISH: 2
}
// Endu
// For Display Status
export const DISPLAY_STATUS = {
  YES: "1",
  NO: "2",
};
// End
// Attendees Status list
export const ATTENDEESSTATUSLIST = [
  { value: 1, label: "Visited" },
  { value: 2, label: "Pending" },
];
// End
// Event format
export const ATTENDEESSTATUS = {
  PENDING: 1,
  VISITED: 2,
};
// End
// Session Group Status 
export const REGISTRATIONSTATUS= [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
];
// End
// Landing Page
export const WEBSITELINKSLANDINGPAGELIST = [
  { value: 1, label: "Summary" },
  { value: 2, label: "Registration" },
  { value: 3, label: "Regret Survey" }
];
// End