import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const SessionList = lazy(() => import("./session-list/SessionList"));
const SessionCreate = lazy(() => import("./session-create/SessionCreate"));
const SessionEdit = lazy(() => import("./session-edit/SessionEdit"));
const SessionView = lazy(() => import("./session-view/SessionView"));

const SessionRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={SessionList} />} />
      <Route
        path="/create"
        element={<EventDetails Component={SessionCreate} />}
      />
      <Route
        path="/:sessionSlug/edit"
        element={<EventDetails Component={SessionEdit} />}
      />
      <Route
        path="/:sessionSlug/view"
        element={<EventDetails Component={SessionView} />}
      />
    </Routes>
  );
};

export default SessionRoutes;
