import { Card, Col, Container, Row } from "react-bootstrap";
import SideBar from "./sidebar/SideBar";
import ThemeSettingContext from "../../../helper/context/theme-settings/ThemeSettingContext";
import { useContext } from "react";
import { useParams } from "react-router-dom";

const EventDetails = ({ Component }) => {
  const { theme } = useContext(ThemeSettingContext); //set theme
  const { slug } = useParams();
  return (
    <>
      <Container fluid>
        <div className="">
          <Row>
            <Col
              xs={12}
              md={3}
              xl={2}
              className="p-0 border-right side-bar-menu d-flex "
            >
              <Card className={`w-100 bg-${theme.THEME}`}>
                <Card.Body className="p-0">
                  <SideBar slug={slug} />
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              md={9}
              xl={10}
              className="p-0  right-box-section sidebar-width"
            >
              <Component />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default EventDetails;
