import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

//Email Invitation routes
const CreateEmail = lazy(() => import("./create-email/CreateEmail"));
const EditEmail = lazy(() => import("./edit-email/EditEmail"));
const EmailInvitationList = lazy(() =>
  import("./emails-invitation-list/EventPageList")
);
//END

const EmailRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={EmailInvitationList} />}
      />
      <Route
        path="/create-email-invitation"
        element={<EventDetails Component={CreateEmail} />}
      />
      <Route
        path="/:emailInvitationSlug/edit-email-invitation"
        element={<EventDetails Component={EditEmail} />}
      />
    </Routes>
  );
};

export default EmailRoutes;
