import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const SeatingList = lazy(() =>
  import("./seating-list/SeatingList")
);
const SeatingCreate = lazy(() =>
  import("./seating-create/SeatingCreate")
);
const SeatingEdit = lazy(() =>
  import("./seating-edit/SeatingEdit")
);
const SeatingView = lazy(() =>
  import("./seating-view/SeatingView")
);
const SeatingRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={SeatingList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={SeatingCreate} />}
      />
       <Route
       path="/:SeatingSlug/edit"
        element={<EventDetails Component={SeatingEdit} />}
      />
       <Route
        path="/:SeatingSlug/view"
        element={<EventDetails Component={SeatingView} />}
      />
    </Routes>
  );
};

export default SeatingRoutes
