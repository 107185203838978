import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const AdvancedRulesList = lazy(() =>
  import("./advanced-rules-list/AdvancedRulesList")
);
const AdvancedRulesCreate = lazy(() =>
  import("./advanced-rules-create/AdvancedRulesCreate")
);
const AdvancedRulesEdit = lazy(() =>
  import("./advanced-rules-edit/AdvancedRulesEdit")
);
const AdvancedRulesView = lazy(() =>
  import("./advanced-rules-view/AdvancedRulesView")
);

const AdvancedRulesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={AdvancedRulesList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={AdvancedRulesCreate} />}
      />
      <Route
        path="/:bundlesSlug/edit"
        element={<EventDetails Component={AdvancedRulesEdit} />}
      />
      <Route
        path="/:bundlesSlug/view"
        element={<EventDetails Component={AdvancedRulesView} />}
      />
    </Routes>
  );
};

export default AdvancedRulesRoutes
