import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";
const AttendeesList = lazy(() => import("./list/AttendeesList"));
const AddInvitee = lazy(() => import("./invitee/AddInvitee"));
const ViewInvitee = lazy(() => import("./invitee/view/ViewInvitee"));
const AttendeesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDetails Component={AttendeesList} />} />
      <Route
        path="/add-invitee"
        element={<EventDetails Component={AddInvitee} />}
      />
      <Route
        path="/:invitee/edit"
        element={<EventDetails Component={AddInvitee} />}
      />
      <Route
        path="/:invitee/view"
        element={<EventDetails Component={ViewInvitee} />}
      />
    </Routes>
  );
};

export default AttendeesRoutes;
