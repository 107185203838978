import AuthSettingState from "./auth-settings/AuthSettingState";
import ThemeSettingState from "./theme-settings/ThemeSettingState";

const RootStateContext = ({ children }) => {
    // Use the useReducer hook if you need to perform any state management or merging
    // const [state, dispatch] = useReducer(reducer, initialState);
  
    return (
        <ThemeSettingState>
            <AuthSettingState>
            {children}
            </AuthSettingState>
        </ThemeSettingState>
    );
  };
  export default RootStateContext