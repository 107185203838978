import axios from "axios";
import { useAuthHeader } from "react-auth-kit";

/**
 * Custom hook for creating an Axios instance for API requests.
 * @returns {import("axios").AxiosInstance} - Axios instance for API requests.
 */
export const useApi = () => {
  const authHeader = useAuthHeader();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader() ?? "", // Use the authorization token obtained from useAuthHeader()
    },
  });

  return api;
};
/**
 * Custom hook for creating an Axios instance for file upload requests.
 * @returns {import("axios").AxiosInstance} - Axios instance for file upload requests.
 */
export const useApiForFile = () => {
  const authHeader = useAuthHeader();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
    headers: {
      "Content-Type": "multipart/form-data", // Set the default Content-Type for all requests
      Authorization: authHeader() ?? "", // Use the authorization token obtained from useAuthHeader()
    },
  });

  return api;
};