import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import EventDetails from "../../EventDetails";

const WeblinksList = lazy(() =>
  import("./weblinks-list/WeblinksList")
);
const WeblinksCreate = lazy(() =>
  import("./weblinks-create/WeblinksCreate")
);
const WeblinksEdit = lazy(() =>
  import("./weblinks-edit/WeblinksEdit")
);


const WeblinksRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EventDetails Component={WeblinksList} />}
      />
      <Route
        path="/create"
        element={<EventDetails Component={WeblinksCreate} />}
      />
      <Route
        path="/:weblinkSlug/edit"
        element={<EventDetails Component={WeblinksEdit} />}
      />
    </Routes>
  );
};

export default WeblinksRoutes
