// import { Navigate } from "react-router-dom";
// import { useAuthUser } from "react-auth-kit";
// import PropTypes from 'prop-types'
// const isAdminAuth = (Component) => {
//   const AuthRoute = (props) => {
//     const auth = useAuthUser();
//     if (auth().role === "admin") {
//       return <Component {...props} />;
//     } else {
//       return <Navigate to="/" />;
//     }
//   };

//   return AuthRoute;
// };
// export default isAdminAuth;


import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import PropTypes from "prop-types";

const AuthRoute = ({ Component }) => {
  const auth = useAuthUser();
  if (auth().role === "admin") {
    return <Component />;
  } else {
    return <Navigate to="/" />;
  };
}

AuthRoute.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

const isAdminAuth = (Component) => {
  return (props) => {
    return <AuthRoute Component={Component} {...props} />;
  };
};

export default isAdminAuth;